const state = {
  loadingStatus:0
}
const getters = {
  getListForm(state){
    return state.condition
  }
}
const mutations = {
  addLoadingStatus(state,num){
    state.loadingStatus+=num
  },
  reduceLoadingStatus(state,num){
    state.loadingStatus-=num
  },
}

export default {
  mutations,
  state,
  getters
}