import store from '@/store/index.js'
import { getCookie } from '@/utils/cookieMethod.js'
import { getPath } from '@/utils/index.js'
const routeArr = ['/login/goLogin', '/login/register', '/login/forgotPassword']
export async function beforeEach(to, from, next) {
  if (window.location.hostname == 'localhost') {
    if (getCookie('saveToken') && getCookie('saveToken') != store.state.user.accessToken) {
      const token = getCookie('saveToken')
      store.commit('user/setAccessToken', token)
    }
  } else {
    if (getCookie('saveToken') != store.state.user.accessToken) {
      const token = getCookie('saveToken')
      store.commit('user/setAccessToken', token)
    }
  }
  // if (process.env.NODE_ENV === 'production') {
  //   if (window.location.hostname.split('.').length > 2) {
  //     next({...from})
  //   }
  // }
  let token = store.getters['user/accessToken']
  const yuming = window.location.host.split('.')
  let yumingStart = yuming.length === 2 ? '' : yuming[0]
  let loginArr = ['/noShop','/error','/404']
  if (token) {
    if (!yumingStart || yumingStart === 'www') {
      const path = getPath()
      window.location.assign(path)
    } else if (process.env.NODE_ENV !== 'development' && to.path === '/officialWebsite') {
      next({ ...from })
    } else if (routeArr.includes(to.path)) {
      next({ path: '/' })
    } else if (loginArr.includes(to.path)) {
      next()
    } else if (store.state.user.userInfo.customer_account_id) {
      next()
    }
    else {
      try{
        await store.dispatch('user/getUserInfo')
        next({ ...to })
      }catch(e){
        if(!e.code){
          next({ path:'/error' })
        }else{
          next({ path:'/noShop' })
        }
      }
    }
  } else {
    const arr = ['login', 'noShop', 'officialWebsite', '404'] //不包含的路由
    const wailian = ['/privacy']

    const isHas = arr.some(i => to.path.indexOf(i) !== -1)
    if (!isHas) {
      store.commit('route/setRedirectUrl', to.path)
    }
    if (routeArr.includes(to.path) || to.path === '/noShop') {
      next()
    } else if (
      process.env.NODE_ENV !== 'development' &&
      ((!yumingStart || yumingStart === 'www') &&
        to.path === '/officialWebsite')) {
      next()
    }
    else if (
      process.env.NODE_ENV === 'development' &&
      to.path === '/officialWebsite') {
      next()
    } else if ((!yumingStart || yumingStart === 'www')) {
      next({ path: '/officialWebsite' })
    } else if (wailian.includes(to.path)) {
      next()
    }
    else {
      next({ path: '/login' })
    }
  }
}