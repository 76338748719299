import { createStore } from "vuex";

const files = require.context('./module', true, /.js/)
let modules = {};
files.keys().forEach(file => {
  modules[file.replace(/(\.\/|\.js)/g, '')] = files(file).default;
})
Object.keys(modules).forEach((key) => {
  modules[key]["namespaced"] = true
})
export default createStore({
  modules
});
