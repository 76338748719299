
const userRouter = {
  path: 'user',  //首页二级头
  name: 'user',
  component: () => import('@/layout/userMenus/index.vue'),
  redirect: '/homeTwoStage/user/userHome',
  children: [
    {
      path: 'userHome', //用户中心首页
      name: 'userHome',
      component: () => import('@/views/user/userHome/index.vue'),
    },
    {
      path: 'myOrder', //我的订单
      name: 'myOrder',
      component: () => import('@/views/user/myOrder/index.vue'),
    },
    {
      path: 'orderInfo', //订单详情
      name: 'orderInfo',
      component: () => import('@/views/user/orderInfo/index.vue'),
    },
    {
      path: 'accountInfo', //账户信息
      name: 'accountInfo',
      component: () => import('@/views/user/accountInfo/index.vue'),
    },
    {
      path: 'creditLimit', //信用额度
      name: 'creditLimit',
      component: () => import('@/views/user/creditLimit/index.vue'),
    },
    {
      path: 'interestShop', //关注店铺
      name: 'interestShop',
      component: () => import('@/views/user/interestShop/index.vue'),
    },
    {
      path: 'personSetting', //人员设置
      name: 'personSetting',
      component: () => import('@/views/user/personSetting/index.vue'),
    },
    {
      path: 'myCollect', //我的收藏
      name: 'myCollect',
      component: () => import('@/views/user/myCollect/index.vue'),
    },
    {
      path: 'addressManage', //地址管理 
      name: 'addressManage',
      component: () => import('@/views/user/addressManage/index.vue'),
    },
    {
      path: 'messageCenter', //消息中心
      name: 'messageCenter',
      component: () => import('@/views/user/messageCenter/index.vue'),
    },
    {
      path: 'messageInfo', //消息详情
      name: 'messageInfo',
      component: () => import('@/views/user/messageInfo/index.vue'),
    },
    {
      path: 'myInquiry', //我的询价单
      name: 'myInquiry',
      component: () => import('@/views/user/myInquiry/index.vue'),
    },
    {
      path: 'inquiryDetail', //我的询价单-详情
      name: 'inquiryDetail',
      component: () => import('@/views/user/inquiryDetail/index.vue'),
    },
  ]
}
export default userRouter