import Login from '@/api/Login.js'
import { setToken, getToken, setBranchId, getBranchId } from '../../utils/token'
import {setCookie} from '@/utils/cookieMethod.js'
const state = () => ({
  accessToken: getToken(),
  userInfo: {},
  branchId: getBranchId(),
  implBranchId: '',
  website: '',
  errorNum:1
})
const getters = {
  accessToken: (state) => state.accessToken,
  getUserInfo: (state) => state.userInfo
}
const mutations = {
  setWebsite(state,data){
    state.website = data
  },
  setAccessToken(state, token) {
    state.accessToken = token
    setToken(token)
  },
  setUserInfo(state, data = {}) {
    state.userInfo = data
  },
  setBranchId(state, id) {
    state.branchId = id
    setBranchId(id)
  },
  setImplBranchId(state, id) {
    state.implBranchId = id
  },
  setErrorNum(state,type) {
    if(type === 'add'){
      state.errorNum += 1
    }else{
      state.errorNum = 1
    }
  },
}

const actions = {
  //账号密码登录
  async userLogin({ commit }, loginInfo) {
    const { data: { access_token, branch_id,website } } = await Login.login(loginInfo)
    commit('setAccessToken', access_token)
    commit('setBranchId', branch_id)
    commit('setWebsite', website)
    const baseYuMing = process.env.VUE_APP_BASE_DOMAIN_NAME
    setCookie('saveToken',access_token,baseYuMing+'.com')
    setCookie('saveWebsite',website,baseYuMing+'.com')
  },
  //短信验证码登录
  async userLoginSms({ commit }, loginInfo) {
    const { data: { access_token, branch_id,website } } = await Login.loginSms(loginInfo)
    commit('setAccessToken', access_token)
    commit('setBranchId', branch_id)
    commit('setWebsite', website)
    const baseYuMing = process.env.VUE_APP_BASE_DOMAIN_NAME
    setCookie('saveToken',access_token,baseYuMing+'.com')
    setCookie('saveWebsite',website,baseYuMing+'.com')
  },
  async getUserInfo({ commit,state }) {
    try{
      const { data } = await Login.accountMe()
      commit('setUserInfo', data)
    }catch(e){
      const code = e.code
      if(!code){
        if(state.errorNum === 3){
          return Promise.reject(e)
        }else{
          commit('setErrorNum','add')
        }
      }else{
        return Promise.reject(e)
      }
    }
  },
  //退出登录
  resetAll({ commit }) {
    commit('setAccessToken', '')
    commit('setBranchId', '')
    commit('setUserInfo',{})
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}