
const information = {
  path: 'information', 
  name: 'information',
  component: () => import('@/layout/ShopInformation/index.vue'),
  redirect: '/homeTwoStage/information/informationList',
  children: [
    {
      path: 'informationList', //资讯列表
      name: 'informationList',
      component: () => import('@/views/informationList/index.vue'),
      meta: {
        title: '商城资讯'
      }
    },
    {
      path: 'informationInfo', //资讯详情
      name: 'informationInfo',
      component: () => import('@/views/informationInfo/index.vue'),
      meta: {
        title: '商城资讯'
      }
    },
  ]
}
export default information