import Http from '@/Http.js'

class Base extends Http {

    constructor() {
        super()
    }


}

export default Base
