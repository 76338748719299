<template>
  <div class="loading-box" v-show="isShow">
    <div class="loading-img">
      <img src="https://ppic.zwgyp.com/mmro/loading.gif"/>
    </div>

  </div>
</template>
<script setup>
import {useStore} from 'vuex'
import {computed} from 'vue'
const $store = useStore()
const isShow = computed(()=>$store.state.loading.loadingStatus > 0)
</script>
<style lang="less" scoped>
.loading-box{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index:9999;
  .loading-img{
    position:absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    overflow: hidden;
    padding: 20px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(10px);
    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }
}
</style>