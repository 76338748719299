export function setToken(token,type='localStorage'){
    if(type === 'localStorage'){
        localStorage.setItem('accessToken',token)
    }else if(type === 'sessionStorage'){
        sessionStorage.setItem('accessToken',token)
    }
}
export function getToken(type='localStorage'){
    if(type === 'localStorage'){
        return  localStorage.getItem('accessToken')
    }else if(type === 'sessionStorage'){
        return  sessionStorage.getItem('accessToken')
    }
}

export function removeToken(type='localStorage'){
    if(type === 'localStorage'){
        localStorage.removeItem('accessToken')
    }else if(type === 'sessionStorage'){
        sessionStorage.removeItem('accessToken')
    }
}

export function setBranchId(id,type='localStorage'){
    if(type === 'localStorage'){
        localStorage.setItem('branchId',id)
    }else if(type === 'sessionStorage'){
        sessionStorage.setItem('branchId',id)
    }
}
export function getBranchId(type='localStorage'){
    if(type === 'localStorage'){
        return  localStorage.getItem('branchId')

    }else if(type === 'sessionStorage'){
        return  sessionStorage.getItem('branchId')
    }
}

export function removeBranchId(type='localStorage'){
    if(type === 'localStorage'){
        localStorage.removeItem('branchId')
    }else if(type === 'sessionStorage'){
        sessionStorage.removeItem('branchId')
    }
}