<script setup>
import loadingView from '@/components/loadingView/index.vue'
import { ref, } from 'vue'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
// import moment from 'moment'
// import 'moment/locale/zh-cn'

import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
const locale = ref(zhCN)
dayjs.locale('zh-cn')


</script>

<template>
  <Suspense>
    <template #default>
      <a-config-provider :locale="locale">
        <router-view />
        <loading-view></loading-view>
      </a-config-provider>
    </template>
    <template #fallback>
      <!-- <loading-view></loading-view> -->
    </template>
  </Suspense>
</template>

<style scoped lang="less">
@import './assets/style/theme.less';
</style>
