import axios from 'axios'
// import qs from 'qs';
import { message } from 'ant-design-vue'
import { deleteCookie } from '@/utils/cookieMethod.js'
import router from '@/router'
import store from '@/store'
import { getToken } from '@/utils/token'
const baseURL = process.env.VUE_APP_BASE_API
const version = ''
const service = axios.create({
  baseURL: `${baseURL}${version}`,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    'platform': 'web'
  },
  timeout: 60000, //请求超时时间
})

// http request 拦截器
service.interceptors.request.use(
  (config) => {
    if (!(window.location.pathname.includes('wechatPay') && config.url === '/v1/order/show')) {
      //记录请求次数
      store.commit('loading/addLoadingStatus', 1)
    }

    const token = getToken()
    let headers = {}
    // 发送请求之前
    if (config.data instanceof FormData) {
      headers['Content-Type'] = 'multipart/form-data'
    }
    if (token) {
      headers['access-token'] = token
    }
    const hostname = window.location.hostname
    let yuming
    if (hostname === 'localhost') {
      yuming = 'http://xiaodu.devzwjcmro.com'
    } else {
      yuming = window.location.origin
    }
    headers['domain'] = yuming
    config.headers = { ...config.headers, ...headers }
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

const handleCode = (code, msg) => {
  switch (code) {
    case 403:
      router.push({ path: '/401' }).catch(() => { })
      break
    case 200001:
      store.dispatch('user/resetAll')
      deleteCookie('saveToken', process.env.VUE_APP_BASE_DOMAIN_NAME + '.com')
      deleteCookie('saveWebsite', process.env.VUE_APP_BASE_DOMAIN_NAME + '.com')
      sessionStorage.setItem('redirectUrl', location.pathname + location.search)
      location.reload()
      break
    case 200005:
      router.push({ path: '/noShop' }).catch(() => { })
      break
    default:
      message.error(msg || `后端接口${code}异常`)
      break
  }
}
let codes = [0, 200004] //默认成功状态的code  200004企业名称已存在
// 响应拦截器即异常处理
service.interceptors.response.use(
  (response) => {
    if (!(window.location.pathname.includes('wechatPay') && response.config.url === '/v1/order/show')) {
      //记录请求次数
      store.commit('loading/reduceLoadingStatus', 1)
    }
    const { data, config } = response
    const { code, message } = data
    if (codes.includes(code) || config.url === '/v1/account/captcha') {
      return data
    }
    else {
      handleCode(code, message)
      return Promise.reject(data)
    }
  },
  (err) => {
    if (!(window.location.pathname.includes('wechatPay') && err.response.config.url === '/v1/order/show')) {
      //记录请求次数
      store.commit('loading/reduceLoadingStatus', 1)
    }
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          console.log('错误请求')
          break
        case 401:
          console.log('未授权，请重新登录')
          break
        case 403:
          console.log('拒绝访问')
          break
        case 404:
          console.log('请求错误,未找到该资源')
          break
        case 405:
          console.log('请求方法未允许')
          break
        case 408:
          console.log('请求超时')
          break
        case 500:
          message.error('服务器端出错')
          break
        case 501:
          console.log('网络未实现')
          break
        case 502:
          console.log('网络错误')
          break
        case 503:
          console.log('服务不可用')
          break
        case 504:
          console.log('网络超时')
          break
        case 505:
          console.log('http版本不支持该请求')
          break
        default:
          console.log(`连接错误${err.response.status}`)
          return Promise.reject({ data: '', error: err })
      }
    } else {
      return Promise.reject({ data: '', error: err })
    }
    return Promise.reject(err.response)
  })
class Http {

  static async get(arg) {
    return await service.request({
      url: arg.url,
      method: 'get',
      params: arg.data,
      ...arg.config
    })
  }

  static async delete(arg) {
    return await service.request({
      url: arg.url,
      method: 'delete',
      params: arg.data,
      ...arg.config
    })
  }

  static async post(arg) {
    return await service.request({
      url: arg.url,
      method: 'post',
      data: arg.data,
      ...arg.config
    })
  }

  static async put(arg) {
    return await service.request({
      url: arg.url,
      method: 'put',
      data: arg.data,
      ...arg.config
    })
  }

  static async patch(arg) {
    return await service.request({
      url: arg.url,
      method: 'patch',
      data: arg.data,
      ...arg.config
    })
  }

}

export default Http
