import Base from './Base.js'

class Login extends Base {

    constructor() {
        super()
    }
    //登录 账号密码
    static async login(data) {
        return await this.post({
            url: '/v1/account/login',
            data
        })
    }
    //短信验证码登录
    static async loginSms(data) {
        return await this.post({
            url: '/v1/account/login_sms',
            data
        })
    }
    //注册
    static async accountRegister(data) {
        return await this.post({
            url: '/v1/account/register',
            ...data
        })
    }
    //手机找回密码
    static async accountResetPassword(data) {
        return await this.post({
            url: '/v1/account/reset_password',
            data
        })
    }
    //发送短信验证码
    static async accountSendSms(data) {
        return await this.post({
            url: '/v1/account/send_sms',
            data
        })
    }
    //获取登录错误次数
    static async loginErrorNum(data) {
        return await this.post({
            url: '/v1/account/login/err_num',
            data
        })
    }
    //获取短信验证码次数
    static async accountSmsNum(data) {
        return await this.post({
            url: '/v1/account/sms/num',
            data
        })
    }
    //获取用户信息
    static async accountMe(data) {
        return await this.get({
            url: '/v1/account/me',
            data
        })
    }
}

export default Login