import { createApp } from 'vue'
import './style.css'

import App from './App.vue'
import router from './router/index.js'
import store from '@/store/index.js'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import './assets/font/iconfont.css'
import './assets/style/base.less'
import vue3SeamlessScroll from 'vue3-seamless-scroll'
import { ConfigProvider } from 'ant-design-vue'
import myBreadcrumb from '@/components/myBreadcrumb/index.js'

// import MetaInfo from 'vue-meta-info'
import IconSvg from '@/components/iconSvg'
let app = createApp(App)
app.component('icon-svg', IconSvg)
app.use(Antd)
app.use(ConfigProvider)
app.use(vue3SeamlessScroll)
app.use(router)
app.use(store)
app.use(myBreadcrumb)
// app.use(MetaInfo)
app.mount('#app')
// onMounted(()=>{
//   document.dispatchEvent(new Event('render-event'))
// })
