<template>
  <svg class="svg-icon" aria-hidden="true" :style="{ 'font-size': fontSize }">
    <use :xlink:href="iconName"></use>
  </svg>
</template>
<script>
  export default {
    name: 'iconSvg',
    props: {
      iconClass: {
        type: String,
        default: '',
      },
      size: {
        type: String,
        default: '14',
      },
    },
    computed: {
      iconName() {
        return `#${this.iconClass}`
      },
      fontSize() {
        return this.size + 'px'
      },
    },
  }
</script>
<style>
  .svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    cursor: pointer;
  }
</style>
