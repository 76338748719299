import Shop from '@/api/Shop.js'
const state = {

  condition: {
    order: '',//正序(asc), 倒序(desc)
    stock: '', //是否显示有库存
    min_price: '',
    max_price: '',
    category_id: '', //分类id
    manufacturers: '',//品牌
    sort: 'sort_order',  //综合(sort_order), 销量(sales),价格(price)
    keyword: '',//到搜索 关键词
  },
  listRes: {}
}
const getters = {
  getListForm(state) {
    return state.condition
  }
}
const mutations = {
  setListFormItem(state, data) {
    const [k, v] = data
    state.condition[k] = v
  },
  setRootSearch(state, data) {
    for (const k in state.condition) {
      state.condition[k] = ''
    }
    state.condition.keyword = data
  },
  setRootClassSearch(state, data) {
    const { key, v } = data
    for (const k in state.condition) {
      state.condition[k] = ''
    }
    state.condition[key] = v
    state.condition['sort'] = 'sort_order'
  },
  clearForm(state) {
    // for(const k in  state.condition){
    //   state.condition[k] = ''
    // }
    state.condition = {
      order: '',//正序(asc), 倒序(desc)
      stock: '', //是否显示有库存
      min_price: '',
      max_price: '',
      category_id: '', //分类id
      manufacturers: '',//品牌
      sort: 'sort_order',  //综合(sort_order), 销量(sales),价格(price)
      keyword: '',//到搜索 关键词
    }
  }
}

const actions = {
  // eslint-disable-next-line no-unused-vars
  async getList({ commit }, data) {
    const res = await Shop.productList(data)
    return res
  }
}
export default {
  actions,
  mutations,
  state,
  getters
}