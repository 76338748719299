const state = {
  redirectUrl:sessionStorage.getItem('redirectUrl') || '' //保存之前的路径
}
const mutations = {
  setRedirectUrl(state,url){
    state.redirectUrl = url
    sessionStorage.setItem('redirectUrl',url)
  },
}

export default {
  mutations,
  state,
}