<template>
  <div class="bread-box">
    <div style="margin-left: 10px;" class="home-title" @click="goHome">首页 &nbsp;/</div>
    <template v-for="(i, index) in breadData" :key="i.id">
      <!-- 有下拉 -->
      <div class="has-select" v-if="!i.name && i.categoryArr" @mouseleave="onMouseleave(i)">
        <div @mouseenter="onMouseenter(i)">
          {{ index == 0 ? oneName : twoName }}
          <icon-svg icon-class="icon-fanyejiantou" style="margin-left:10px;
        transform:rotate(90deg);
        font-size:12px"></icon-svg>
          &nbsp;{{ index == breadData.length - 1 ? '' : '/' }}
          <div class="select-list" v-show="i.isShow">
            <div class="item-select" v-for="child in i.categoryArr" :key="child.id" :style="{
              'color': index == 0 ? selectedKeysOne == child.id ? '#DC533E' : ''
                : selectedKeysTwo == child.id ? '#DC533E' : '',
            }" @click="menuClick(child, index)">
              {{ child.name }}</div>
          </div>
        </div>
      </div>
      <!-- 无下拉 -->
      <div v-else @click="breadClick(i)" class="no-select">
        <div>
          <span style="margin-left:10px;cursor:pointer">
            {{ i.name }}
          </span>
          <span>
            &nbsp;{{ index == breadData.length - 1 ? '' : '/' }}
          </span>
        </div>
      </div>
    </template>
  </div>
</template>
<script setup>
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
const emits = defineEmits(['clickBreadText', 'clickBreadMenu'])
const $router = useRouter()
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  breadData: {
    type: Array,
    default: () => []
  }
})
watch(() => props.breadData, () => {
  changeBreadData()
})
let oneName = ref('')
let twoName = ref('')
let selectedKeysOne = ref('')
let selectedKeysTwo = ref('')
const changeName = (item, idx) => {
  if (idx == 0) {
    oneName.value = item.name
    selectedKeysOne.value = item.id
  } else {
    twoName.value = item.name
    selectedKeysTwo.value = item.id
  }
  item.isShow = false
}
//移入
const onMouseenter = (item) => {
  item.isShow = true
}
//移出
const onMouseleave = (item) => {
  item.isShow = false
}
const goHome = () => {
  $router.push({ path: '/homeTwoStage/home' })
}
const changeBreadData = () => {
  props.breadData.forEach((i, idx) => {
    i.categoryArr && i.categoryArr.forEach((__i) => {
      if (i.id == __i.id) {
        changeName(__i, idx)
      }
    })
  })
}
const menuClick = (item, idx) => {
  changeName(item, idx)
  emits('clickBreadMenu', item.id)
}
const breadClick = (item) => {
  emits('clickBreadText', item)
}
defineExpose({
  changeBreadData
})
</script>
<style lang="less" scoped>
@import './index.less';
</style>