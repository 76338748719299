function setCookie(cName, value, domain) {
  let t= new Date();
	t.setTime(t.getTime()+1*24*60*60*1000)
  // exDate.setDate(exDate.getDate() + expireDate);
  // const res = cName + "=" + decodeURIComponent(value) +
  // (expireDate == null ? "" : ";expires=" + exDate.toUTCString()) + ";path=/;domain=" + domain;
  const res = `${cName}=${value};domain=${domain};expires="${t.toGMTString()};path=/`
  document.cookie = res
}
function getCookie(key) {
  return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*"
    + encodeURIComponent(key).replace(/[-.+*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
}
function deleteCookie(name,domain) {
  name = name + '=';
  let c = document.cookie.split(";")
  for (let i = 0; i < c.length; i++) {
    c[i] = c[i].trim()
    if (c[i].indexOf(name) == 0){
      document.cookie = name + "=;expires=" + "Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain="+domain;
    }
  }
}
export {
  setCookie,
  getCookie,
  deleteCookie
}
