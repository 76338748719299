const state = {
  isShowSelect: false,
  isShowSelectHeader: false,
  headerData: [],
  homeData: [],
  copyHomeData:[]
}
const mutations = {
  setIsShowSelectHeader(state, data) {
    state.isShowSelectHeader = data
  },
  setIsShowSelect(state, data) {
    state.isShowSelect = data
  },
  setHomeData(state, data) {
    state.homeData = data
    // if(state.copyHomeData.length===0){
    //   state.copyHomeData = JSON.parse(JSON.stringify(data))
    // }
  },
  setHeaderData(state, data) {
    state.headerData = data
  },

  updateHomeData(state, data) {
    // index 第几个模块 moduleName 模块名 要修改的list tabIndex 第几个tab
    const { moduleIndex, moduleName, list, tabIndex, total } = data
    const pinlei = ['categoryFloorTwo', 'categoryFloorOne']
    const moduleData = state.homeData[moduleIndex].formParams
    if (pinlei.includes(moduleName)) {
      moduleData.settingProduct[tabIndex].products = list
    } else if (moduleName === 'recommendGoods') {
      // console.log(moduleData.recommendGoods[tabIndex].page,'~~~~~~~~~page')
      moduleData.recommendGoods[tabIndex].setGoods.push(...list)
      moduleData.recommendGoods[tabIndex].total = total || 0
      if (moduleData.recommendGoods[tabIndex].setGoods.length < total) {
        moduleData.recommendGoods[tabIndex].page += 1
      }
    }
  },
  updatePage(state, data) {
    const { moduleIndex, page, tabIndex } = data
    const moduleData = state.homeData[moduleIndex].formParams
    moduleData.recommendGoods[tabIndex].page = page
  }
}


export default {
  state,
  mutations

}