import {localGetItem,localSetItem} from '@/utils/index.js'
const state = {
  shoppingStepState: 0,
  shoppingCartArr:[],
  kuaijieData:localGetItem() || {},
  informationId:''
}
const getters = {
  getState: (state) => state.shoppingStepState,
  getShoppingCartList:(state)=>state.shoppingCartArr
}
const mutations = {
  setShoppingStepState(state, data) {
    state.shoppingStepState = data
  },
  setShoppingCartList(state,data){
    state.shoppingCartArr = data
  },
  setKuaiJie(state,data){
    state.kuaijieData = data
    localSetItem(data)
  },
  setInformationId(state,data){
    state.informationId = data
  }
}

const actions = {

}
export default {
  actions,
  mutations,
  state,
  getters
}