import { createRouter, createWebHistory } from 'vue-router'

import userRouter from './userRouter' //用户中心路由
import information from './information' //用户中心路由
import { beforeEach } from './intercept'
let routes = [
  {
    path: '/',
    name: '/',
    component: () => import('@/layout/homeLayout/index.vue'), //一级头
    redirect: '/homeTwoStage/home',
    children: [
      {
        path: 'homeTwoStage',  //首页二级头
        name: 'homeTwoStage',
        component: () => import('@/layout/homeTwoStage/index.vue'),
        children: [{ //首页
          path: 'home',
          name: 'home',
          component: () => import('@/views/home/index.vue'),
          meta: {
            title: '首页'
          }
        },
        {
          path: 'brandCenter', //品牌中心
          name: 'brandCenter',
          component: () => import('@/views/brandCenter/index.vue'),
          meta: {
            title: '品牌中心'
          }
        },
        {
          path: 'specialSubject/:id', //专题页面
          name: 'specialSubject',
          component: () => import('@/views/specialSubject/index.vue'),
          meta: {
            title: '专题'
          }
        },
        {
          path: 'list', //列表页
          name: 'list',
          component: () => import('@/views/ProductList/index.vue'),
          meta: {
            title: '商品列表'
          }
        },
        {
          path: 'productInfo', //详情页
          name: 'productInfo',
          component: () => import('@/views/productInfo/index.vue'),
          meta: {
            title: '商品详情'
          }
        },
        {
          path: 'quickOrder', //快捷下单
          name: 'quickOrder',
          component: () => import('@/views/quickOrder/index.vue'),
          meta: {
            title: '快捷下单'
          }
        },
        {
          path: 'intelligentInquiry', //智能询价
          name: 'intelligentInquiry',
          component: () => import('@/views/intelligentInquiry/index.vue'),
          meta: {
            title: '智能询价'
          }
        },
        {
          path: 'help', //帮助中心
          name: 'help',
          component: () => import('@/views/help/index.vue'),
          meta: {
            title: '帮助中心'
          }
        },

          userRouter,
          information
        ]
      },
      {
        path: 'shoppingCart', //购物车二级头
        name: 'shoppingCart',
        redirect: '/shoppingCart/shoppingCartStart',
        component: () => import('@/layout/shoppingCartHeader/index.vue'),
        children: [{
          path: 'shoppingCartStart', //购物车第一个页面
          name: 'shoppingCartStart',
          component: () => import('@/views/shoppingCart/shoppingCartStart/index.vue'),
        },
        {
          path: 'orderForGoods', //购物车第二个页面
          name: 'orderForGoods',
          component: () => import('@/views/shoppingCart/orderForGoods/index.vue'),
        },
        {
          path: 'cashier', //购物车第三个页面 收银台
          name: 'cashier',
          component: () => import('@/views/shoppingCart/cashier/index.vue'),
        },
        {
          path: 'wechatPay', //微信
          name: 'wechatPay',
          component: () => import('@/views/shoppingCart/wechatPay/index.vue'),
        },
        {
          path: 'bankTransfer', //银行转账 paySuccess
          name: 'bankTransfer',
          component: () => import('@/views/shoppingCart/bankTransfer/index.vue'),
        },
        {
          path: 'paySuccess', //支付成功
          name: 'paySuccess',
          component: () => import('@/views/shoppingCart/paySuccess/index.vue'),
        }]
      },

    ]
  },
  {
    path: '/login',
    name: 'login',
    redirect: { name: 'goLogin' },
    component: () => import('@/layout/login/index.vue'),
    children: [
      {
        path: 'goLogin',
        name: 'goLogin',
        component: () => import('@/views/login/index.vue'),
        mete: {
          title: '登录'
        }
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('@/views/register/index.vue'),
        mete: {
          title: '注册'
        }
      },
      {
        path: 'forgotPassword',
        name: 'forgotPassword',
        component: () => import('@/views/forgotPassword/index.vue'),
        mete: {
          title: '忘记密码'
        }
      }
    ]
  }, {
    path: '/noShop',
    name: 'noShop',
    component: () => import('@/views/noShop/index.vue'),
    mete: {
      title: '没店铺'
    }
  },
  {
    path: '/officialWebsite',
    name: 'officialWebsite',
    component: () => import('@/views/officialWebsite/index.vue'),
    mete: {
      title: '官网'
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/privacy/index.vue'),
    mete: {
      title: '隐私'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404/index.vue'),
    mete: {
      title: '404'
    }
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/errorPage/index.vue'),
    mete: {
      title: 'error'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]

const router = createRouter({
  scrollBehavior() {
    // 始终滚动到顶部
    return { top: 0 }
  },
  history: createWebHistory(),
  routes
})
router.beforeEach(beforeEach)
export default router