import { getCookie } from '@/utils/cookieMethod.js'

//防抖
export function debounce(cb, delay = 500) {
  let timer = null
  return function (...args) {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      cb.call(this, ...args)
      timer = null
    }, delay)
  }
}
//节流
export function throttle(cb, delay = 500) {
  let timer = null
  return function (...args) {
    if (!timer) {
      timer = setTimeout(() => {
        cb.call(this, ...args)
        timer = null
      }, delay)
    }
  }

}
//计算购物车数量 加减 公式
export function handlerQuantity(num, qiding, beishu) {
  const minNum = Math.ceil(qiding / beishu) * beishu
  if (num % beishu == 0 && num >= qiding) {
    return Number(num)
  } else {
    let num1 = Math.ceil(num / beishu) * beishu
    if (num1 < minNum) {
      num1 = minNum
    }
    return Number(num1)
  }
}
//千分符
export function numberFormat(str) {
  let arr = ['number', 'string']
  if (!str) return str

  if (!arr.includes(typeof str)) return str
  if (typeof str === 'number') {
    str = str + ''
  }
  var reg = str.indexOf('.') > -1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;
  // /(\d)(?=(?:\d{3})+$)/g
  return str.replace(reg, '$1,')
}
//校验手机号
export function phoneTest(str) {
  if (!str) return false
  let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
  return reg.test(str)
}
//秒数 转 时分秒
export function getTime(time, isDay) {
  if (!time & time !== 0) return time
  let day = parseInt(time / 60 / 60 / 24)
  day = day < 10 ? '0' + day : day
  let h = 0
  if (isDay) {
    h = parseInt(time / 60 / 60 % 24)
    h = h < 10 ? '0' + h : h
  } else {
    h = parseInt(time / 60 / 60)
    h = h < 10 ? '0' + h : h
  }
  let m = parseInt(time / 60 % 60)
  m = m < 10 ? '0' + m : m
  let s = parseInt(time % 60)
  s = s < 10 ? '0' + s : s
  if (isDay) {
    return [day, h, m, s]
  }
  return [h, m, s]
}
//去掉字符串中所有空格
let reg = /\s+/g
export function deleteSpace(str) {
  if (typeof str !== 'string') return str
  return str.replace(reg, '')
}
//参数转成params url格式
export const toParams = (obj) => {
  if (Object.prototype.toString.call(obj).slice(8, -1) !== 'Object') return obj
  let str = ''
  for (const k in obj) {
    str += `${k}=${obj[k]}&`
  }
  str = str.slice(0, -1)
  return str
}
//浏览器路径参数转成对象
export function urlToParams(str) {
  if (!str) return

  let paramsObj = {}

  const arr = str.slice(1).split('&')
  arr.forEach(i => {
    const newArr = i.split('=')
    const [k, v] = newArr
    paramsObj[k] = v
  })
  return paramsObj
}
//深拷贝 简易版 只考虑常用类型
export function deepCopy(str) {
  if (Object.prototype.toString.call(str).slice(8, -1) === 'Array') {
    let arr = str.map((i) => {
      return deepCopy(i)
    })
    return arr
  } else if (Object.prototype.toString.call(str).slice(8, -1) === 'Object') {
    let obj = {}
    for (const k in str) {
      obj[k] = deepCopy(str[k])
    }
    return obj
  } else {
    return str
  }
}
export function isEmail(value) {
  const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
  return reg.test(value)
}
//判断是否为数字
export function isNumber(value) {
  const reg = /^[0-9]*$/
  return reg.test(value)
}
export function localSetItem(data, key = 'shortcut') {
  try {
    if (typeof data === 'string') {
      localStorage.setItem(key, str)
      return
    }
    const str = JSON.stringify(data)
    localStorage.setItem(key, str)
  } catch (e) {
    console.log(e)
  }
}
export function localGetItem(key = 'shortcut') {
  try {
    const str = localStorage.getItem(key)
    const obj = JSON.parse(str)
    return obj
  } catch (e) {
    console.log(e)
  }
}
export function getPath() {
  let website = getCookie('saveWebsite')
  const hostArr = window.location.host.split('.')
  if (hostArr.length === 2) {
    website && hostArr.unshift(website)
  } else if (hostArr.length >= 3) {
    hostArr[0] = website
  }
  const path = window.location.protocol + '//' + hostArr.join('.') + '/homeTwoStage/home'
  return path
}
