import Base from './Base.js'

class Shop extends Base {

  constructor() {
    super()
  }
  //获取关注店铺列表
  static async branchList(data) {
    return await this.post({
      url: '/v1/branch/list',
      data
    })
  }
  //获取商品列表
  static async productList(data) {
    return await this.post({
      url: '/v1/product/list',
      data
    })
  }
  //获取商品分类
  static async categories(data) {
    return await this.get({
      url: '/v1/categories',
      data
    })
  }
  //获取单个商品分类及所有子分类
  static async categoriesShow(data) {
    return await this.get({
      url: '/v1/categories/show',
      data
    })
  }
  //商品详情
  static async productsShow(data) {
    return await this.get({
      url: '/v1/products/show',
      data
    })
  }
  //获取热销商品
  static async productsHot(data) {
    return await this.get({
      url: '/v1/product/hot',
      data
    })
  }
  //获取列表关联品牌项
  static async productBrand(data) {
    return await this.post({
      url: '/v1/product/brand',
      data
    })
  }
  //获取列表关联分类项
  static async productCategory(data) {
    return await this.post({
      url: '/v1/product/category',
      data
    })
  }
}

export default Shop